<template>
    <div>
        <CCard class="p-2">

            <form enctype="multipart/form-data">
                <CCardHeader>
                    <CRow style="color: #1352a1;">

                        <CCol lg="6">
                            <h5 v-if="!editMode">Create Work Order </h5>
                            <h5 v-else>Update Work Order </h5>
                        </CCol>
                        <CCol lg="6">
                            <div style="text-align: right">
                                <CButton size="sm" class="btn btn-ghost-dark mr-1" @click="backToTable()" >Cancel</CButton> 
                                <CButton class="px-3 mr-1" :disabled="odoHasError" size="sm" 
                                        color="success" @click="saveWorkOrder($event,'draft')"><i class="fa fa-save"></i> Save</CButton>
                                <CButton class="mr-1" :disabled="odoHasError"  active-class="" size="sm" color="info" @click="saveWorkOrder($event,'submitted')" v-if="!this.editMode"><i class="fa fa-save"></i> Submit</CButton>
                            </div>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody class="card-form">
                    <CCard class="pl-4 pr-4">
                        <CRow class="p-2 mb-1 mt-3 bg-secondary text-dark">
                            <CCol lg="12">
                                <strong style="font-size:12px"> Work Order Details</strong>
                            </CCol>

                        </CRow>
                        <br>

                        <CRow>
                            <CCol lg="8">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email <span class="text-danger">*</span></label>
                                    <CInput type="email" placeholder="" v-model="dataParams.email" required />

                                </div>
                            </CCol>
                            <CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1"> Depot <span class="text-danger"> *</span></label>
									<v-select 
										@input="computeclientrate"
										label="setting_name" 
										:options="depotList.data"
										:reduce="item => item.id"
										v-model="dataParams.depot_id"
										placeholder="-Select-" 
										:disabled="JSON.parse(window.localStorage.getItem('user_data')).depot_id == null ? false : true"
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.depot_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
                            <!-- <CCol lg="4">
                                <div class="form-group">
                                    <label class="mb-1"> Depot <span class="text-danger"> *</span></label>
                                    <v-select label="setting_name" :options="depotList.data" :reduce="item => item.id"
                                        v-model="dataParams.depot_id" placeholder="-Select-">
                                        <template #search="{ attributes, events }">
                                            <input class="vs__search" :required="!dataParams.depot_id" v-bind="attributes"
                                                v-on="events" />
                                        </template>
                                    </v-select>
                                </div>
                            </CCol>
                            -->
                        </CRow>
                        
                        <CRow>
                           
                            <CCol lg="4">
                                <div class="form-group">
                                    <label class="mb-1"> Plate No. <span class="text-danger"> *</span></label>
                                    <v-select 
                                        label="plate_no" 
                                        :options="vehicleList.data" 
                                        :value="dataParams.vehicle_info"
                                        placeholder="-Select-" 
                                        id="vehicle_no" 
                                        @input="vehicleChange" 
                                        :reduce="item => item.id"
                                        v-model="dataParams.vehicle_id">
                                       
                                        <template #search="{ attributes, events }">
                                            <input class="vs__search" :required="!dataParams.vehicle_id" v-bind="attributes"
                                                v-on="events" />
                                        </template>
                                    </v-select>
                                </div>
                            </CCol>
                           
                            <CCol lg="4">
                                <label>
                                    Odometer <span class="text-danger">*</span>
                                </label>
                                <i class="fa fa-question-circle ml-1" title="Reading should be maximum of 6 characters." ></i>
                                <CInput
                                v-mask="'######'"
                                type="number"
                                placeholder=""
                                :class="{'has-error' : odoHasError}"
                                :disabled="validating_odometer"
                                v-model="dataParams.odo_reading"
                                @change="odometerChanged"
                                required
                                /> 
                                <div class="mb-3" style="font-size:11px" >
                                    <span>Current Odometer: 
                                        <span v-if="!data_loading.odometer">
                                            {{ dataParams.current_odometer ? dataParams.current_odometer : 0 }} KM</span> 
                                        <span v-else><i class="fa fa-spin fa-loading"></i> Fetching ....</span>
                                    </span>
                                </div>
                            </CCol>
                            <CCol lg="4">
                                <div class="form-group">
                                    <label>Date Reported<span class="text-danger"> *</span></label>
                                    <Datepicker 
											@selected="dateSelected"
											input-class="form-control bg-white" 
											v-model="dataParams.date_needed"
											:typeable="true" 
											required 
										>
									</Datepicker>  
                                </div>
                            </CCol>
					
			
                          

                        </CRow>

                        <CRow>
                            <CCol lg="12">
                                <label>Scope of work</label>
                                <CTextarea v-model="dataParams.scope_of_work" placeholder="" />
                            </CCol>
                        </CRow>



                    </CCard>
                    <CCard class="p-3 mt-0">

                        <!--  PARTS REQUEST -->

                        <CRow class="p-2 mb-1 bg-secondary text-dark">
                            <CCol lg="12">
                                <strong style="font-size:12px"> Material Request</strong>
                            </CCol>

                        </CRow>
                        <br>
                        <CRow v-for="(item, index) in dataParams.work_order_part_request">
                            <CCol lg="5" md="12" sm="12">
                                <div class="form-group">
                                    <label>Description</label>
                                    <v-select label="part_name" :options="partList.data" :reduce="item => item.id"
                                        v-model="dataParams.work_order_part_request[index].part_inventory_id"
                                        @input="changeDesc(index)">
                                    </v-select>
                                </div>
                            </CCol>
                            <CCol lg="2" md="12" sm="12">
                                <label>Qty </label>
                                <CInput type="number" @input="changeQty(index)" @keypress="isNumber($event)"
                                    v-model="dataParams.work_order_part_request[index].qty" />
                            </CCol>
                            <CCol lg="2" md="12" sm="12">
                                <label>Cost</label>
                                <CInput type="number" @input="changeQty(index)" @keypress="isNumber($event)"
                                    v-model="dataParams.work_order_part_request[index].unit" />
                            </CCol>
                            <CCol lg="2" md="12" sm="12">
                                <label>Total</label>
                                <CInput :value="compute_total(index)" placeholder="0.00" disabled />
                            </CCol>
                            <CCol lg="1" v-if="$route.params.id ? true : index != 0">
                                <br />
                                <CButton size="sm" color="danger mt-2" @click="deletePartItem(index)"><i
                                        class="fa fa-trash"></i></CButton>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="2">
                                <CButton size="sm" color="success mb-2" @click="addPartItem"><i class="fa fa-plus"></i> New
                                    Item
                                </CButton>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow class="p-2 mb-1 bg-secondary text-dark">
                            <CCol lg="12">
                                <strong style="font-size:12px"> Labor Request</strong>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="12">
                                <label>Labor Description</label>
                                <CTextarea v-model="dataParams.labor_description" placeholder="" />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="4">
                                <label>Labor Amount</label>
                                <CInput type="number" v-model="dataParams.labor_amount" placeholder="" />
                            </CCol>
                        </CRow>


                        <CRow>
                            <CCol lg="9" class="text-right"></CCol>
                            <CCol lg="3" class="text-left">
                                <h6>Cost Summary</h6>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="9" class="text-right"></CCol>
                            <CCol lg="3" class="text-right">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item d-flex justify-content-between align-items-center py-1"
                                        style="font-size:12px">
                                        Labor
                                        <strong>{{ dataParams.labor_amount|CurrencyFormatWithSign }}</strong>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center py-1"
                                        style="font-size:12px">
                                        Material
                                        <strong>{{ material_total|CurrencyFormatWithSign }}</strong>
                                    </li>

                                    <li class="list-group-item d-flex justify-content-between align-items-center"
                                        style="font-weight:700; font-size:18px">
                                        TOTAL
                                        <strong> {{ summary_total(material_total, dataParams.labor_amount)|CurrencyFormatWithSign }} </strong>
                                    </li>

                                </ul>

                            </CCol>
                        </CRow>
                    </CCard>
                    <CCard class="p-3 mt-0">

                        <CRow class="p-2 mb-1 bg-secondary text-dark">
                            <CCol lg="12">
                                <strong style="font-size:12px">Job Order Details</strong>
                            </CCol>

                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="6">
                                <label>Job Order Type - Purchase Order</label>
                                <p style=" margin-top: -8px;">
                                    <small style="color:blue">Put for P.O or "N/A" if not applicable</small>
                                </p>
                                <CInput v-model="dataParams.purchase_order_job_order_type" placeholder="" />
                            </CCol>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label class="mb-1"> Supplier Name (P.O)</label>
                                    <p style=" margin-top: -8px;">
                                        <small style="color:blue">Select supplier name </small>
                                    </p>
                                    <v-select label="vendor_name" :options="vendorList.data" :reduce="item => item.id"
                                        v-model="dataParams.purchase_order_vendor_id" placeholder="-Select-">
                                        <template #search="{ attributes, events }">
                                            <input class="vs__search" :required="!dataParams.purchase_order_vendor_id"
                                                v-bind="attributes" v-on="events" />
                                        </template>
                                    </v-select>
                                </div>
                            </CCol>

                        </CRow>
                        <CRow>
                            <CCol lg="6">
                                <label>Job Order Type - Cash Advance</label>
                                <p style=" margin-top: -8px;">
                                    <small style="color:blue">Put for Cash Advance or "N/A" if not applicable</small>
                                </p>
                                <CInput v-model="dataParams.cash_advance_job_order_type" placeholder="" />
                            </CCol>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label class="mb-1"> Supplier Name (Cash Advance)</label>
                                    <p style=" margin-top: -8px;">
                                        <small style="color:blue">Select supplier name</small>
                                    </p>
                                    <v-select label="vendor_name" :options="vendorList.data" :reduce="item => item.id"
                                        v-model="dataParams.cash_advance_vendor_id" placeholder="-Select-">
                                        <template #search="{ attributes, events }">
                                            <input class="vs__search" :required="!dataParams.cash_advance_vendor_id"
                                                v-bind="attributes" v-on="events" />
                                        </template>
                                    </v-select>
                                </div>
                            </CCol>

                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <div class="form-group">
                                    <label for="exampleFormControlFile1">Supplier Quotation</label>
                                    <label>
                                        <a target="_blank"
                                            :href="main_path + '/incident_documents/' + dataParams.documents"></a>
                                    </label>
                                   
                                    <input class="form-control-file" type="file" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*,.pdf,.doc,.docx">
                                    <!-- <input class="form-control-file" type="file" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*,.pdf,.doc,.docx"> -->
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <label>Requestor Notes</label>
                                <CTextarea v-model="dataParams.requestor_note" placeholder="" />
                            </CCol>
                        </CRow>
                    </CCard>
                </CCardBody>
            </form>
        </CCard>
    </div>
</template>
<script>
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import Datetime from 'vuejs-datetimepicker';
import vSelect from 'vue-select'
import '../style.css';
import moment from 'moment';

export default {
    mounted() {
        this.getDepot();
        this.getVehicle();
        this.getPartsList();
        this.getVendor();
        if(this.$route.params?.id) {
            this.getData();
            this.editMode = true;
        }
    },
    data() {
        return {
            main_path: config.main_path,
            user_details: JSON.parse(window.localStorage.getItem('user_data')),
            company_type: JSON.parse(window.localStorage.getItem('user_data')).company.company_type,
            window,
            JSON,
            config,
            odo_reading: '',
            message: "",
            validating_odometer: false,
            odo_error: false,
            data_loading: {
                odometer: false
            },
            dataParams: {
                email: '',
                date_needed: '',
                vehicle_id: '',
                scope_of_work: '',
                depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
                work_order_labor_request:[],
                work_order_part_request: [
                    {
                        part_inventory_id: "",
                        unit: "",
                        qty: "",
                        total: "",
                    }
                ],
                labor_description: '',
                labor_amount: '',
                purchase_order_job_order_type: '',
                purchase_order_vendor_id: '',
                cash_advance_job_order_type: '',
                cash_advance_vendor_id: '',
                requestor_note: '',
                file:null,
                asset_type: 'vehicle',
                status:'',
                odo_reading:'',
                message: "",
                documents_s3 : 0
            },
            
            depotName: "",
            depotList: [],
            vehicleList: [],
            partList: [],
            vendorList: [],
            editMode:false,
            summary:0,
        }
    },
    components: { Datepicker, vSelect, Datetime },
    methods: {
        backToTable(){
	    	this.$router.push('/main/work_order')
	    },
        getData(){
            this.$showLoading(true)
            axios.get(config.api_path+"/workOrder/"+this.$route.params.id)
                .then(response=>{
                    this.$showLoading(false)
                    const {data} = response.data;
                  
                    for (const key in data) {
                        if (Object.hasOwnProperty.call(this.dataParams, key)) {
                            const element = data[key];
                            this.dataParams[key] = element;
                        }
                    }
                    this.dataParams.odo_reading = response.data.data.odometer_reading?.reading;
                    this.dataParams.current_odometer = this.dataParams.odo_reading;

                    this.dataParams.date_needed = this.$options.filters.epochToDate(this.dataParams.date_needed);
                    // console.log(data);
                })
                .catch(err => {
                this.$showLoading(false)
            })
        },
        filesChange(name, file) {
            const files = file.length ? file[0] : null;
            this.dataParams.file = null;
            if(files && files.size) {
                if(config.fileSize(files.size) <= 5) {
                    this.dataParams.file = files;
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'File size must not be greater than 5MB',
                        icon: 'error',
                    })
                    const f = document.querySelector('.form-control-file');
                    f.value = '';
                }
            }
            // console.log(file[0].size, config.fileSize(file[0].size), 'SIZE')
        },
        saveWorkOrder(e ,type) {
            var temp_date = this.dataParams.date_needed;
            e.preventDefault();
            var ax = {};
            const header = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            
            if(!this.dataParams?.status)
                this.dataParams.status = type;

            let formData = new FormData();
            this.dataParams.date_needed = this.$options.filters.DateToEpoch(this.dataParams.date_needed);
            let payload = {};
            for (const property in this.dataParams) {
                // payload[property] = this.dataParams[property] ?? null;
                formData.append(property, this.dataParams[property]);
            }
            
            formData.append('vehicle_id', this.dataParams.vehicle_id);
            formData.append('work_order_part_request', JSON.stringify(this.dataParams.work_order_part_request));
            formData.append('work_order_labor_request', JSON.stringify(this.dataParams.work_order_labor_request));
            formData.append('summary_total', JSON.stringify(this.summary));
            
            // payload['vehicle_id'] = this.dataParams.vehicle_id;
            // payload['work_order_part_request'] = JSON.stringify(this.dataParams.work_order_part_request);
            // payload['work_order_labor_request'] = JSON.stringify(this.dataParams.work_order_labor_request);
            // payload['summary_total'] = JSON.stringify(this.summary);
            

            if (this.editMode) {
                ax = axios.post(config.api_path + "/workOrderTrucking/" + this.$route.params.id, formData);
            }
            else {
                // this.dataParams.status = type;
                ax = axios.post(config.api_path + "/workOrder", formData);
            }
            
            ax.then(response => {
                if(response.data.status == "failed"){
					this.message = response.data.message;
					this.dataParams.date_needed = temp_date;
					return;
				}
				this.message = ""

                var text = 'Work order successfully added!';
                if (this.editMode) {
                    text = 'Work order successfully updated!';
                }
                Swal.fire({
                    title: 'Success!',
                    text,
                    icon: 'success',
                })
                this.isLoading = false;
                this.$router.push('/main/view_work_order_trucking/' + response.data.data.id)
            }).catch(e => {
				this.isLoading = false;
			})
        },
        getVehicle() {
            axios.get(config.api_path + '/reference/vehicle-list', {
                params: {
                    // with_odometer:'1'
                }
            }).then(response => {
                this.vehicleList = response.data;
            })
        },
        getDepot() {
            axios.get(config.api_path + '/reference/settings-list', {
                params: {
                    setting_type: 'depot'
                }
            }).then(response => {
                this.depotList = response.data;
            })
        },
        getVendor() {
            axios.get(config.api_path + "/reference/vendors-list")
            .then(response => {
                this.vendorList = response.data;
            })
        },

        dateSelected(ev) {
			this.$nextTick(() => {
				this.dataParams.date = this.dataParams.date_needed ? moment(this.dataParams.date_needed).format('MM/DD/YYYY') : null; 
            	this.odometerChanged(this.dataParams.odo_reading)
			});
		},


        odometerChanged(ev) {
			this.odo_error = false;
			this.validating_odometer = true;
			axios.post(`${config.api_path}/odometer/validate`,{
				vehicle_id : this.dataParams.vehicle_id,
				date: this.dataParams.date,
				reading : ev,
			}).then(res => {
				const {status, message} = res.data;
				if(status == 'error') {
					let text = message;
					Swal.fire({
						title: 'Ooops!',
						text,
						icon: 'error', 
					})
					this.odo_error = true;
				}
				this.validating_odometer = false;

			}).catch(err => {
				this.validating_odometer = false;
			})
		},
        

        vehicleChange(i) {
            this.data_loading.odometer = true;
            
            axios.get(`${config.api_path}/vehicle/odometer/${i}`).then(res => {
                const { data, status } = res.data;
                const odo = data?.odo_reading ?? "0";
                this.$set(this.dataParams, 'current_odometer', odo);
                this.data_loading.odometer = false;
            }).finally(fin => {
                this.data_loading.odometer = false;
            })
        },
        addPartItem() {
            this.dataParams.work_order_part_request.push({
                part_inventory_id: "",
                unit: 0,
                qty: 0,
                total: 0,
            })
        },
        deletePartItem(index) {
            this.dataParams.work_order_part_request.splice(index, 1);
        },
        getPartsList() {
            axios.get(config.api_path + '/partInventory?page=1&limit=10000000').then(response => {
                this.partList = response.data;
                this.partList.data = this.partList.data.map((value, index) => {
                    value.value = value.id;
                    value.label = value.part_name;
                    return value;
                })
            })
        },
        changeDesc(index) {
            this.dataParams.work_order_part_request[index].unit = this.partList.data.filter((value) => {
                return this.dataParams.work_order_part_request[index].part_inventory_id == value.id;
            })[0].cost;
        },
        changeQty(index) {
            var part_total = parseFloat(0);
            var labor_total = parseFloat(0);

            var part_request = this.dataParams.work_order_part_request;
            for (var i = 0; i < part_request.length; i++) {
                part_total += parseFloat(part_request[i].qty * part_request[i].unit);
            }
            this.part_total = part_total.toFixed(2);
            var labor_request = this.dataParams.work_order_part_request;
            for (var i = 0; i < labor_request.length; i++) {
                labor_total += parseFloat(labor_request[i].qty * labor_request[i].unit);
            }
            this.labor_total = labor_total.toFixed(2);
            // console.log(this.part_total)
        },
        summary_total(material_total, labor_total){
            let labor = labor_total ? labor_total: 0;
            let material = material_total ? material_total: 0;
            this.summary = parseFloat(material)+parseFloat(labor);
            return this.summary;
        }
    },
    computed: {
        odoHasError() {
            return this.odo_error;
        },
        compute_total() {
            return index => {
                return this.dataParams.work_order_part_request[index].unit * this.dataParams.work_order_part_request[index].qty;
            }
        },
        material_total: function(){
            let total = 0;
            this.dataParams.work_order_part_request.forEach(data => {
                let times = parseFloat(data.unit)*parseFloat(data.qty);
                data.total = times;
                total = total+times;
            });
            return total;
        },
    }
}
</script>
<style>
.has-error {
    border: 1px solid red !important;
}</style>